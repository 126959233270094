import Contacts from "../../../components/home/Contacts";
import Service from "../../../components/home/Service";
import { useRef } from "react";
import { easeInOut, useInView } from "framer-motion";
import { motion } from "framer-motion";
const ContactsPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const comomonAnim = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1,
        duration: 0.7,
        ease: easeInOut,
      },
    },
    hiddenLeft: {
      opacity: 0,
      x: -150,
    },
    hidden: {
      opacity: 0
    }
  }
  return (
    <section id="contacts" className="bg-gradient-to-t from-30% from-black/90 to-[#4D4D4D] relative">
      <div ref={ref} className="flex gap-6 flex-col xl:w-11/12 w-full xl:flex-row justify-center pt-24 pb-20 relative mx-auto h-fit text-white">
        <div className="section_num right-0 translate-x-[-10%] translate-y-[-30%] top-0">
          <p>04</p>
        </div>
        <motion.div
          variants={comomonAnim}
          initial="hiddenLeft"
          animate={isInView ? "visible" : "hiddenLeft"}
        >
          <Contacts />
        </motion.div>
        <motion.div
          variants={comomonAnim}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <Service />
        </motion.div>
      </div>
    </section>
  );
};

export default ContactsPage;