
import { Route, Routes } from "react-router-dom";
import Home from "./C_House/pages/home/home";
import ServicePage from "./C_House/pages/service/servicePage";

import CLayout from "./C_House/layout/CLayout";
import BLayout from "./Bathello/layout/BLayout";

import BathelloPages from "./Bathello/pages/BathelloPages";
const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CLayout><Home /></CLayout>} />
        <Route path="/service" element={<CLayout><ServicePage /></CLayout>} />
        <Route path="bathello" element={<BLayout><BathelloPages /></BLayout>} />
      </Routes>
    </>
  );
};

export default App;
