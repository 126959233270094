import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import "../../../../index.scss";
import SwiperFade from "../../../../components/Swiper";
import table_1 from "../../../../assets/images/table_1.png"
import table_2 from "../../../../assets/images/table_2.png"
import table_3 from "../../../../assets/images/table_3.png"
import table_4 from "../../../../assets/images/table_4.png"
import table_5 from "../../../../assets/images/table_5.png"
import "../BprodsGrid"
import "../index.scss"

const BSwiperCabinets = () => {
    return (
        <SwiperFade>
            <div className="cabinets">
                <SwiperSlide>
                    <img className="swiper_img" id="table_1" sizes="100vw" src={table_1} alt="cabinets img" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_2" sizes="100vw" src={table_2} alt="cabinets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_3" sizes="100vw" src={table_3} alt="cabinets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_4" sizes="100vw" src={table_4} alt="cabinets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_5" sizes="100vw" src={table_5} alt="cabinets img" loading="lazy" />
                </SwiperSlide>
            </div>
        </SwiperFade>
    );
};

export default BSwiperCabinets;
