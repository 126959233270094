import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { BiX } from "react-icons/bi";

const BurgerModal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        if (!isOpen) {
            document.body.style.overflowY = "scroll";
        } else {
            document.body.style.overflowY = "hidden";
        }
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ x: "-100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "-100%" }}
                    transition={{ duration: 0.3 }}
                    className="w-full h-screen bg-black/85 fixed left-0 top-0 z-30"
                    onClick={onClose}
                >
                    {children}
                    <div onClick={onClose} className="absolute right-3 top-3">
                        <BiX size={48} className="text-white"></BiX>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default BurgerModal;
