import React, { useContext, useEffect, useState } from "react";
import PagesContext from "../../context/PagesContext";

const BNavigation = ({ hide, setHide, sections }) => {
    const { toggleNextComponent } = useContext(PagesContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1280);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ul className="flex flex-col xl:flex-row justify-center xl:justify-start h-full xl:h-auto xl:gap-6 gap-3 items-center">
            <a href="/" className="xl:text-xl text-3xl flex gap-[5px] font-semibold">
                <span className="text-red-600">Climate</span>
                <span className="text-blue-600">House</span>
            </a>
            {!isMobile &&
                <button onClick={() => setHide(!hide)} className="text-white xl:my-auto xl:text-xl text-3xl">ShowRoom</button>
            }
            {sections.map((page, index) => (
                <li className="header_navs text-3xl cursor-pointer" key={index} onClick={() => toggleNextComponent(page.id)}>
                    {page.name}
                </li>
            ))}
        </ul>
    );
};

export default BNavigation;