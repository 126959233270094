import { useTranslation } from "react-i18next";
import "./index.scss"
import { Link } from "react-router-dom";

const Nav = () => {
    const { t } = useTranslation();

    const scrollToAbout = () => {
        window.location.href = '/#about';
    };

    const scrollToPartners = () => {
        window.location.href = '/#partners';
    };

    const scrollToContacts = () => {
        window.location.href = '/#contacts';
    };

    const sections = [
        { section: "#about", title: t("about us"), href: scrollToAbout },
        { section: "#partners", title: t("partners"), href: scrollToPartners },
        { section: "#contacts", title: t("contacts"), href: scrollToContacts },
    ];


    const Pages = [
        { link: "/", title: t("main") },
        { link: "/service", title: t("service") },
    ]

    return (
        <nav>
            <ul className="flex flex-col h-screen gap-3 items-center justify-center">
                <li>
                    <Link to="/bathello" className="bathello_color text-3xl">
                        Bathello
                    </Link>
                </li>
                {Pages.map((item, i) => (
                    <li key={i}>
                        <Link to={item.link} className="nav_links text-3xl text-white">
                            {item.title}
                        </Link>
                    </li>
                ))
                }
                {sections.map((item, i) => (
                    <li key={i}>
                        <a href={item.section} onClick={item.href} className="nav_links text-3xl text-white">
                            {item.title}
                        </a>
                    </li>
                ))
                }
            </ul>
        </nav>
    )
}
export default Nav