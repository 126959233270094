import bathello_show_room from "../../../assets/images/bathello_showroom.webp";
import bathello_show_room_1 from "../../../assets/images/bathello_showroom_1.webp";
import bathello_show_room_2 from "../../../assets/images/bathello_showroom_2.webp";
import bathello_show_room_3 from "../../../assets/images/bathello_showroom_3.webp";
import bathello_show_room_4 from "../../../assets/images/bathello_showroom_4.webp";
import "../../index.scss";
import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import SwiperFade from "../../../components/Swiper";

const BSwiper = () => {
    return (
        <SwiperFade>
            <SwiperSlide>
                <img className="swiper_img hide_img" sizes="100vw" src={bathello_show_room} alt="showRoom img" />
            </SwiperSlide>
            <SwiperSlide className="">
                <img className="swiper_img hide_img" sizes="100vw" src={bathello_show_room_1} alt="showRoom img" />
            </SwiperSlide>
            <SwiperSlide className="">
                <img className="swiper_img hide_img" sizes="100vw" src={bathello_show_room_2} alt="showRoom img" />
            </SwiperSlide>
            <SwiperSlide className="">
                <img className="swiper_img hide_img" sizes="100vw" src={bathello_show_room_3} alt="showRoom img" />
            </SwiperSlide>
            <SwiperSlide className="">
                <img className="swiper_img hide_img" sizes="100vw" src={bathello_show_room_4} alt="showRoom img" />
            </SwiperSlide>
        </SwiperFade>
    );
};

export default BSwiper;
