import { useTranslation } from "react-i18next";

import MarqueeComp from "../../../components/home/Marquee";
const _4Section = () => {
  const { t } = useTranslation();

  return (
    <section id="partners" className="py-20 bg-gradient-to-b from-10% from-black/90 to-black/85">
      <div className="w-11/12 mx-auto flex flex-col gap-6">
        <h1 className="text-center text-3xl xl:text-[50px] text-[#CCCCCC]">
          {t("our partners")}
        </h1>
        <div className="mx-auto relative w-60">
          <div className="bg-[#CCCCCC] w-[2px] rounded mx-auto h-10 xl:h-20"></div>
          <div className="section_num absolute top-[30%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            05
          </div>
        </div>
        <MarqueeComp />
      </div>
    </section>
  );
};

export default _4Section;
