import "./index.scss"

const CentersList = ({ item, i }) => {

    return (
        <>
            <li className="centerWrapper">
                <span className="index_num w-12">№{i + 1}</span>
                <div className="mx-4 w-3/12">
                    <h3 className="text-white">{item.location}</h3>
                    <p className="w-56">{item.adress}</p>
                </div>
                <a rel="noreferrer" href={`tel:+998${item.phone_1}`} target="_blank">
                    <div className="font-sans xl:w-80 flex flex-col">
                        <span>
                            {"+998 " + item.phone_1 + ` (${item.name_1})`}
                        </span>
                        <span>
                            {item.phone_2 ? ("+998 " + item.phone_2 + (item.name_2 ? ` (${item.name_2})` : ` (${item.name_1})`)) : null}
                        </span>
                    </div>
                </a>
                <div className="schedule_div">
                    <p>
                        {item.schedule}
                    </p>
                    <span className="text-[#2ecc71]">
                        8:00
                    </span>
                    {" "}
                    -
                    {" "}
                    <span className="text-red-500">
                        19:00
                    </span>
                </div>
            </li>
        </>
    )
}
export default CentersList