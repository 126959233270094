import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import "../../../../index.scss";
import SwiperFade from "../../../../components/Swiper";
import mixer_1 from "../../../../assets/images/mixer_1.png"
import mixer_2 from "../../../../assets/images/mixer_2.png"
import mixer_3 from "../../../../assets/images/mixer_3.png"
import mixer_4 from "../../../../assets/images/mixer_4.jpg"
import mixer_5 from "../../../../assets/images/mixer_5.jpg"
import "../BprodsGrid"
import "../index.scss"

const BSwiperMixers = () => {
    return (
        <SwiperFade>
            <div className="shower">
                <SwiperSlide>
                    <img className="swiper_img" id="mixer_1" src={mixer_1} alt="mixer img" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="mixer_2" src={mixer_2} alt="mixer img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={mixer_3} alt="mixer img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={mixer_4} alt="mixer img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={mixer_5} alt="mixer img" loading="lazy" />
                </SwiperSlide>
            </div>
        </SwiperFade>
    );
};

export default BSwiperMixers;