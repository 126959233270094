import { useTranslation } from "react-i18next";
import "../index.scss";
import { useEffect, useRef, useState } from "react";
import { easeInOut, useInView } from "framer-motion";
import { motion } from "framer-motion";
import useWindowSize from "../../../hooks/useWindowSize";
import { FaArrowDown } from "react-icons/fa";

const Advantages = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const size = useWindowSize()
  const isInView = useInView(ref, { once: true });

  const AdvantagesData = [
    { title: t("appealing design"), descr: t("appealing design text"), text: "#CCCCCC", bg: "#1E1E1E", shadow: "#1E1E1E" },
    { title: t("personal approach"), descr: t("personal approach text"), text: "#ADD8E6", bg: "#556B78", shadow: "#556B78" },
    { title: t("quality and savety"), descr: t("quality and savety text"), text: "#00CED1", bg: "#001F3F", shadow: "#001F3F" },
    { title: t("comfort that you deserve"), descr: t("comfort that you deserve text"), text: "#D8C3A5", bg: "#555555", shadow: "#555555" },
  ];


  const [toggleStates, setToggleStates] = useState({});

  useEffect(() => {
    const newStates = {};
    AdvantagesData.forEach((_, index) => {
      newStates[index] = size.width >= 768;
    });
    setToggleStates(newStates);
  }, [size.width, AdvantagesData.length]);

  const handleToggle = (index) => {
    if (size.width < 768) {
      setToggleStates(prev => ({
        ...prev,
        [index]: !prev[index]
      }));
    }
  };

  const getAnimationProps = (isInView, isToggled) => {
    const baseProps = {
      opacity: isInView ? 1 : 0,
      y: isInView ? 0 : 200
    };

    if (size.width < 768) {
      return {
        ...baseProps,
        height: isToggled ? 'auto' : "90px",
      };
    }

    return baseProps;
  }

  return (
    <section className="w-full relative py-24 bg-gradient-to-b from-10% from-black/90 to-[#4D4D4D]">
      <div className="section_num left-[4%] top-[6%] text-black">03</div>
      <div ref={ref} className="flex flex-col items-center mx-auto justify-center relative w-11/12">
        <motion.h1
          className="text-[44px] text-center xl:text-6xl mb-16 text-white"
          initial={{ opacity: 0, scale: 0.6 }}
          animate={!isInView ? { opacity: 0, scale: 0.6 } : { opacity: 1, scale: 1 }}
          transition={{ duration: 0.7, ease: easeInOut, delay: .5 }}
        >
          {t("why")}
          <br className="xl:hidden" />
          <span className="text-[#e10808]/85"> Climate</span>
          <span className="text-[#1130d1]/85"> House</span>?
        </motion.h1>
        <ul className={`flex flex-wrap xl:flex-nowrap justify-between xl:justify-center ${size.width > 768 ? "gap-2" : "gap-6"} items-center text-[#CCCCCC]`}>
          {AdvantagesData.map((item, i) => (
            <motion.li
              key={i}
              className={`_3Section_blocks ${size.width > 768 ? "h-72 justify-center" : "h-fit"}`}
              style={{
                background: item.bg,
                color: item.text,
                boxShadow: item.shadow,
                cursor: 'pointer',
                overflow: 'hidden'
              }}
              onClick={() => handleToggle(i)}
              initial={{ opacity: 0, y: 200 }}
              animate={getAnimationProps(isInView, toggleStates[i])}
              transition={{ duration: 0.4, ease: "easeInOut", delay: 0.5 }}
            >
              <div className={`flex items-center justify-between px-3 pt-4`}>
                <p className={size.width > 768 ? "mx-auto text-center" : ""}>{item.title}</p>
                {size.width < 768 && <FaArrowDown className={`w-7 h-7 transition-transform duration-1000 ${toggleStates[i] ? "rotate-180" : ""}`} />}
              </div>
              <motion.div
                className="h-fit px-3 pt-[20px] pb-3 "
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: toggleStates[i] ? 'auto' : 0,
                  opacity: toggleStates[i] ? 1 : 0
                }}
                transition={{
                  height: { ease: "easeInOut" },
                  opacity: { duration: 1, ease: "easeInOut" }
                }}
              >
                <span className={`overflow-hidden ${size.width > 768 ? "text-center" : "text-left"}`}>{item.descr}</span>
              </motion.div>
            </motion.li>
          ))}
        </ul>
      </div>
    </section >
  );
};

export default Advantages;
