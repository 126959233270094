import { useTranslation } from "react-i18next";
import CentersList from "../../../components/service/centersList";
import React, { useCallback, useState } from "react";
import { Suspense } from 'react';


const Centers = () => {
    const [toggleList, settoggleList] = useState(true);
    const { t } = useTranslation();

    const List = [
        {
            location: t("Tashkent"),
            adress: t("address"),
            phone_1: "78 777-50-55",
            name_1: "Сall center",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Samarkand"),
            adress: t("Titova"),
            name_1: t("Sergey"),
            name_2: t("Oleg"),
            phone_1: "93 334-77-66",
            phone_2: "91 530-07-97",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Andijon"),
            adress: t("address"),
            name_1: t("Umarjan"),
            phone_1: "99 993-94-74",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Qõqon"),
            adress: t("address"),
            name_1: t("Sokhibjan"),
            phone_1: "90 306-37-32",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Jizzakh"),
            adress: t("address"),
            name_1: t("Shahrukh"),
            phone_1: "99 554-51-10",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Navoiy"),
            adress: t("address"),
            name_1: t("Sardor"),
            phone_1: "99 403-45-17",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Bukhoro"),
            adress: t("address"),
            name_1: t("Bakhrillo"),
            name_2: t("Hakim"),
            phone_1: "91 418-82-88",
            phone_2: "93 080-76-76",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Surkhandaryo"),
            adress: t("address"),
            name_1: t("Nematullo"),
            phone_1: "99 112-92-22",
            schedule: `${t("schedule")} `
        },
        {
            location: t("Namangan"),
            adress: t("address"),
            name_1: t("Hikmatillo"),
            phone_1: "91 348-28-77",
            phone_2: "94 714-44-74",
            schedule: `${t("schedule")} `
        },
    ]

    const toggleFn = useCallback(() => {
        settoggleList(prevState => !prevState);
    }, []);

    const mapBtnClass = toggleList ? "text-white" : "bg-white/90";
    const listBtnClass = toggleList ? "bg-white/90" : "text-white";

    const MapComponent = React.lazy(() => import('../../../components/service/centersMap'));

    return (
        <section className="w-full bg-gradient-to-b from-black/95 to-black/90">
            <div className="w-11/12 relative mx-auto py-20">
                <div className="flex items-center flex-wrap mb-6 justify-center gap-6 xl:justify-between">
                    <h2 className="text-5xl xl:text-4xl text-white text-center" >{t("centers list")}</h2>
                    <div className="list_btns">
                        <button onClick={toggleFn} className={listBtnClass}>{t("list")}</button>
                        <button onClick={toggleFn} className={mapBtnClass}>{t("map")}</button>
                    </div>
                </div>
                <div className="relative">
                    {toggleList ?
                        <ul className="centers_box">
                            {List.map((item, i) => (
                                < CentersList key={i} i={i} item={item} />
                            ))}
                        </ul>
                        :
                        <Suspense fallback={<div className="text-3xl text-center text-white">Loading...</div>}>
                            <MapComponent loading="lazy" />
                        </Suspense>
                    }
                </div>
            </div>
        </section>
    )
}
export default Centers