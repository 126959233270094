import { useState } from "react";
import PagesContext from "../context/PagesContext";


const BLayout = ({ children }) => {
    // const [next, setNext] = useState(false);
    // const [currentId, setCurrentId] = useState("main");
    const [page, setPage] = useState({
        isOpen: false,
        currentPage: "main"
    });

    const toggleNextComponent = (index) => {
        setPage({ ...page, isOpen: true });

        setTimeout(() => {
            setPage({ isOpen: false, currentPage: index });
        }, 500);
    };

    return (
        <PagesContext.Provider value={{ page, toggleNextComponent }}>
            {children}
        </PagesContext.Provider>
    )
}
export default BLayout;