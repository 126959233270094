import Location from "../../../assets/icons/location.webp";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { LuArrowRight } from "react-icons/lu";
import phone_icon from "../../../assets/icons/phone_icon.png"
import Logo from "../../../assets/images/C_house_logo.png"

const Service = () => {
  const { t } = useTranslation();

  const service = useMemo(() => [
    { name: "Santex Profi", img: Logo, phone: "+998-87-777-50-55", location: t("Tashkent") },
    { name: t("Sergey"), img: Logo, phone: "+998-93-334-77-66", location: t("Samarkand") },
    { name: t("Bakhrillo"), img: Logo, phone: "+998-91-418-82-88", location: t("Bukhoro") },
    { name: t("Umarjan"), img: Logo, phone: "+998-99-993-94-74", location: t("Andijon") },
  ], [t])

  return (
    <div
      className="xl:max-w-full mx-auto"
    >
      <Link to="service">
        <button className="learn-more ml-5">
          <span className="circle" aria-hidden="true">
            <LuArrowRight className="arrow" />
          </span>
          <span className="button-text text-4xl xl:text-5xl">{t("service")}</span>
        </button> 
      </Link>
      <div className="flex flex-col xl:flex-row xl:w-fit gap-6 items-center">
        <div className="flex flex-wrap justify-center gap-3">
          {service.map((item, i) => (
            <div key={i} className="service_block">
              <img className="profile_img bg-white/5 " src={item.img} width={112} alt="climate house logo" />
              <div className="ul">
                <h3 className="w-44 text-center">{item.name}</h3>
                <div className="li_1">
                  <img src={Location} width={20} height={20} alt="location icon" />
                  <p>{item.location}</p>
                </div>
                <div className="li_2">
                  <img src={phone_icon} width={24} height={24} alt="phone icon" />
                  <p>{item.phone}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default Service;
