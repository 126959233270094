import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import "../../../../index.scss";
import SwiperFade from "../../../../components/Swiper";
import sink_1 from "../../../../assets/images/sink_1.webp"
import sink_2 from "../../../../assets/images/sink_2.webp"
import sink_3 from "../../../../assets/images/sink_3.webp"
import "../BprodsGrid"
import "../index.scss"

const BSwiperSinks = () => {
    return (
        <SwiperFade>
            <div className="cabinets">
                <SwiperSlide>
                    <img className="swiper_img" src={sink_1} alt="sink img" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={sink_2} alt="mixer img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={sink_3} alt="mixer img" loading="lazy" />
                </SwiperSlide>
            </div>
        </SwiperFade>
    );
};

export default BSwiperSinks;
