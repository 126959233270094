import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
const CLayout = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
            <SpeedInsights />
            <Analytics />
        </ >
    );
};
export default CLayout;