import gmail from "../../../assets/icons/gmail.webp";
import telegram from "../../../assets/icons/telegram.webp";
import instagram from "../../../assets/icons/instagram.webp"
import facebook from "../../../assets/icons/facebook.png"
import { useTranslation } from "react-i18next";
import "../../index.scss";

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <div
      className="bg-white/5 w-11/12 h-fit mx-auto xl:w-fit relative shadow-2xl shadow-white/5 rounded-xl rounded-tl-none px-3 xl:px-8 pb-10 pt-7"
    >
      <h1 className="text-4xl xl:text-5xl mb-8">{t("contacts")}</h1>
      <div className="block_container absolute bg-white/5 flex items-center pl-5 gap-1 rounded-tl-xl top-0 translate-y-[-100%] left-0 w-1/2 h-8">
        <div className="bg-white/10 rounded-xl w-[10px] h-[10px]"></div>
        <div className="bg-white/10 rounded-xl w-[10px] h-[10px]"></div>
        <div className="bg-white/10 rounded-xl w-[10px] h-[10px]"></div>
      </div>
      <div className="flex gap-6">
        <div className="flex flex-col gap-4 xl:gap-6 items-start">
          <div className="flex items-center gap-2">
            <img className="w-8 xl:w-12 h-8 xl:h-12" width={32} height={32} src={gmail} alt="gmail icon" />
            <a rel="noreferrer" href="mailto:climatehousesam@gmail.com" target="_blank">
              <span className="text-[#CCCCCC] text-sm xl:text-lg">
                Email address
              </span>
              <p className="font-sans text-xs xl:text-base">
                climatehousesam
              </p>
            </a>
          </div>
          <a href="https://www.instagram.com/climate_house_samarqand/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-3">
            <img src={instagram} width={28} height={28} className="w-7 xl:w-11" alt="instagram icon" />
            <div>
              <span className="text-red-600 text-sm xl:text-lg">Instagram</span>
              <p className="text-xs xl:text-base">Climate House</p>
            </div>
          </a>
        </div>
        <div className="flex flex-col gap-4 xl:gap-6 items-start">
          <div>
            <a
              href="https://m.facebook.com/climatehousesam"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-3"
            >
              <img className="w-7 xl:w-12" width={28} height={28} src={facebook} alt="facebook icon" />
              <div>
                <span className="text-blue-500 text-sm xl:text-lg">
                  Facebook
                </span>
                <p className="text-xs xl:text-base">Climate House</p>
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://t.me/SantexProfiService9660"
              rel="noreferrer"
              target="_blank"
              className="flex items-center gap-3"
            >
              <img className="w-7 xl:w-12 h-7 xl:h-12" width={28} height={28} src={telegram} alt="telegram icon" />
              <div>
                <span className="text-sky-500 text-sm xl:text-lg">Telegram</span>
                <p className="text-xs xl:text-base">SantexProfiService</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
