
import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import "../../index.scss"
const CreatePage = ({ swiperClass, Status, description, title, swiper }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1280);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="w-11/12 relative flex flex-col xl:flex-row items-center justify-center gap-6 xl:justify-between top-0 h-full mx-auto">
            <div className="xl:w-[50%] w-full">
                <motion.h1
                    initial={{ height: 0 }}
                    animate={Status ? { height: 0 } : { height: "auto" }}
                    transition={{ duration: 0.4 }}
                    className="text-2xl xl:text-4xl w-full overflow-hidden text-white pb-2">{title}
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, scale: 0.6 }}
                    animate={Status ? { opacity: 0, scale: 0.6 } : { opacity: 1, scale: 1 }}
                    transition={{ duration: 0.4 }}
                    className="xl:text-lg text-lg text-white"
                >
                    {description}
                </motion.p>
            </div>
            <motion.div
                className={`${swiperClass}`}
                initial={{ opacity: 0, scale: .6 }}
                animate={Status ? { opacity: 0, scale: .6 } : { opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                {swiper}
            </motion.div>
        </div >
    )
}

export default CreatePage
