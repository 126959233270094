import "../index.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Form from "../../../../components/form";
import Message from "../../../../components/message";
import CSwiper from "../../../components/home/CSwiper";
const Main = () => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [status, setStatus] = useState(false);

  const handleMessage = (value) => {
    setStatus((prevStatus) => prevStatus !== value ? value : prevStatus);
  };
  return (
    <section
      id="main"
      className="h-full xl:h-screen relative bg-gradient-to-t from-1% from-black/85 via-40% via-black/35 to-black/0"
    >
      <Message status={status} />
      <Form
        isOpen={isOpened}
        onClose={() => setIsOpened(false)}
        handleMessage={handleMessage}
      />
      <CSwiper className="w-full h-screen" />
      <div className="main_page_bg absolute z-[0] w-full h-full"></div>
      <div id="main_text" className="main_text flex w-full flex-col items-center">
        <h2 className= "text-white/95 text-3xl md:text-4xl lg:text-5xl xl:text-[4rem] text-center mx-auto">
          {t("welcome")}
        </h2>
        <h1 className="text-[#e10808]/90 text-3xl font-medium md:text-4xl lg:text-5xl xl:text-[4rem] text-center mx-auto">
          Climate <span className="text-[#1130d1]/90">House</span>
        </h1>
        <div className="xl:w-16 w-8 rounded my-4 xl:my-8 h-[1px] xl:h-[3px] bg-white/90"></div>
        <h3 className="text-white text-center text-xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto">
          {t("your home your climate")}
        </h3>
      </div>
      <div className="absolute right-1/2 bottom-[4%] xl:bottom-[17%] translate-x-1/2">
        <button onClick={() => setIsOpened(true)} className="submit_btn">
          {t("feedback")}
        </button>
      </div>
    </section>
  );
};

export default Main;