import CreatePage from "../components/common/createPage";
import BSwiperCabinets from "../components/ProdsPage/swipers/Cabinets";
import BSwiperToilets from "../components/ProdsPage/swipers/Toilets";
import BSwiperSinks from "../components/ProdsPage/swipers/Sink";
import BSwiperMixers from "../components/ProdsPage/swipers/mixers"
import CreateMainPage from "../components/ProdsPage/CreateMainPage";

import "../../index.scss"
import "./index.scss"
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCreative, Parallax, Thumbs } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const BRpodsPage = ({ Status }) => {
    const { t } = useTranslation();
    const swiperRef = useRef(null); // Reference to the Swiper instance
    const [activeIndex, setActiveIndex] = useState(0);

    const ProdsTabs = [
        {
            component: <CreateMainPage
                Status={Status}
            />,
            name: t("products")
        },
        {
            component: <CreatePage
                title={t("cabinets title")}
                description={t("cabinets descr")}
                swiper={<BSwiperCabinets />}
                Status={Status}
                swiperClass={"cabinets_swiper"}
            />,
            name: t("cabinets")
        },
        {
            component: <CreatePage
                title={t("toilets title")}
                description={t("toilets descr")}
                swiper={<BSwiperToilets />}
                Status={Status}
                swiperClass={"toilets_swiper"}
            />,
            name: t("toilets")
        },
        {
            component: <CreatePage
                title={t("showermixers title")}
                description={t("showermixers descr")}
                swiper={<BSwiperMixers />}
                Status={Status}
                swiperClass={"prods_swiper"}
            />,
            name: t("showermixers")
        },
        {
            component: <CreatePage
                title={t("sinks title")}
                description={t("sinks descr")}
                swiper={<BSwiperSinks />}
                Status={Status}
                swiperClass={"sinks_swiper"}
            />,
            name: t("sinks")
        },
    ]

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

    console.log(swiperRef.current);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1280);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <div className="absolute top-0 bottom-0 z-10 left-0 right-0">
            <div className="relative h-screen">
                <Swiper
                    ref={swiperRef}
                    modules={[Autoplay, Parallax, EffectCreative, Thumbs]}
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    effect={'creative'}
                    creativeEffect={{
                        prev: {
                            shadow: true,
                            translate: [0, 0, -800],
                            rotate: [180, 0, 0],
                        },
                        next: {
                            shadow: true,
                            translate: [0, 0, -800],
                            rotate: [-180, 0, 0],
                        },
                    }}
                    speed={800}
                    className="mySwiper h-screen"
                >
                    {ProdsTabs.map((section, index) => (
                        <SwiperSlide key={index}>
                            {section.component}
                        </SwiperSlide>
                    ))}
                </Swiper>
                {!isMobile &&
                    <div className="tabs absolute z-10 bottom-0 left-1/2 text-white flex gap-3 -translate-x-1/2">
                        {ProdsTabs.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => swiperRef.current?.swiper.slideTo(index)}
                                className={`tab-button px-6 text-xl py-4 ${index === activeIndex ? 'tabs_active' : ''}`}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                }
            </div>
        </div >
    );
};

export default BRpodsPage;
