import { useTranslation } from "react-i18next";
import "./index.scss"
import { HiOutlineMail } from "react-icons/hi";
import { VscFeedback } from "react-icons/vsc";
import { useState } from "react";
import Message from "../../../components/message";
import Form from "../../../components/form";
import { FaInstagram } from "react-icons/fa";
const Footer = () => {

  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const [status, setStatus] = useState(false);

  const handleMessage = (value) => {
    setStatus((prevStatus) => prevStatus !== value ? value : prevStatus);
  };
  return (
    <div className="footer_daddy">
      <Message
        status={status} />
      <Form
        isOpen={isOpened}
        onClose={() => setIsOpened(false)}
        handleMessage={handleMessage}
      />
      <div className="flex h-fit gap-6">
        <a href="https://t.me/SantexProfiService9660" rel="noreferrer" target="_blank">
          <HiOutlineMail className="text-white w-5 h-5" />
        </a>
        <button className="form_btn" onClick={() => setIsOpened(true)}>
          <VscFeedback className="text-white w-5 h-5" />
        </button>
        <a
          href="https://www.instagram.com/climate_house_samarqand/"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram className="text-white w-5 h-5" />
        </a>
      </div>
      <p className="text-white text-xs xl:text-base">© 2024. {t("all rights reserved")}</p>
    </div>
  );
};

export default Footer;