import Logo from "../../../assets/images/Logo.webp";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Burger from "../../../components/burger";
import { Link } from "react-router-dom";
import { useCallback, useEffect } from "react";
import Nav from "./burgerNav";
const Header = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = useCallback((e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
  }, [i18n]);

  useEffect(() => {
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const scrollToAbout = () => {
    window.location.href = '/#about';
  };

  const scrollToPartners = () => {
    window.location.href = '/#partners';
  };
  const scrollToMain = () => {
    window.location.href = '/#main';
  };

  const scrollToContacts = () => {
    window.location.href = '/#contacts';
  };
  return (
    <header className="header py-[6px] px-4 fixed left-1/2 xl:px-12 translate-x-[-50%] z-20 mx-auto xl:gap-4 flex items-center justify-center">
      <Burger>
        <Nav />
      </Burger>
      <>
        <a className="nav_links header_links mobile_style" href="#main" onClick={scrollToMain}>{t("main")}</a>
        <a className="nav_links header_links mobile_style" href="#about" onClick={scrollToAbout}>{t("about us")}</a>
        <a className="nav_links header_links mobile_style" href="#partners" onClick={scrollToPartners}>{t("partners")}</a>
        <Link to="/" rel="nofollow">
          <img className="w-12 xl:w-20" width="48" height="48" src={Logo} alt="Climate House logo" />
        </Link>
        <a className="nav_links header_links mobile_style" href="#contacts" rel="nofollow" onClick={scrollToContacts}>{t("contacts")}</a>
        <Link className="nav_links header_links mobile_style" to="/service">{t("service")}</Link>
        <Link to="/bathello" className="bathello_color xl:text-[25px] hidden text-[21px] xl:block">Bathello</Link>
      </>
      <select
        onChange={handleLanguageChange}
        className="toggle_lang"
      >
        <option className="bg-black text-xs xl:text-base" value="ru">Ru</option>
        <option className="bg-black text-xs xl:text-base" value="en">En</option>
      </select>
    </header>
  );
};

export default Header;
