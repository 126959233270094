import show_room from "../../../assets/images/show_room_.webp";
import show_room_2 from "../../../assets/images/show_room_2.webp";
import show_room_3 from "../../../assets/images/show_room_3.webp";
import show_room_4 from "../../../assets/images/show_room_4.webp";
import show_room_5 from "../../../assets/images/show_room_5.webp";
import "./index.scss";
import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import SwiperFade from "../../../components/Swiper";

const CSwiper = () => {
  return (
    <SwiperFade>
      <SwiperSlide>
        <img className="swiper_img" width={1920} height={1080} src={show_room} alt="show room img" />
      </SwiperSlide>
      <SwiperSlide className="">
        <img className="swiper_img" width={1920} height={1080} src={show_room_2} alt="show room img" />
      </SwiperSlide>
      <SwiperSlide className="">
        <img className="swiper_img" width={1920} height={1080} src={show_room_3} alt="show room img" />
      </SwiperSlide>
      <SwiperSlide className="">
        <img className="swiper_img" width={1920} height={1080} src={show_room_4} alt="show room img" />
      </SwiperSlide>
      <SwiperSlide className="">
        <img className="swiper_img" width={1920} height={1080} src={show_room_5} alt="show room img" />
      </SwiperSlide>
    </SwiperFade>
  );
};

export default CSwiper;
