import { memo, useMemo } from "react";
import "../../index.scss";
import aterflo from "../../../assets/images/aterflo.webp";
import Bathello from "../../../assets/images/Bathello.webp";
import bugatti from "../../../assets/images/buggati.webp";
import eurohot from "../../../assets/images/eurohot.webp";
import itap from "../../../assets/images/itap.webp";
import proaqua from "../../../assets/images/proaqua.webp";
import best_therm from "../../../assets/images/best_therm.webp"
import k_flex from "../../../assets/images/k_flex.webp"
import royal_thermo from "../../../assets/images/royal_thermo.webp";
import tank from "../../../assets/images/tank.webp";
import Marquee from "react-fast-marquee";

const MarqueeComp = memo(function MarqueeComp() {
  // Добавление стиля для каждого изображения
  const companies = useMemo(() => [
    { img: aterflo, style: "w-32 xl:w-60 mx-4" },
    { img: Bathello, style: "w-40 xl:w-96 mx-4" },
    { img: k_flex, style: "w-20 xl:w-40 mx-4" },
    { img: bugatti, style: "w-32 xl:w-64 mx-4" },
    { img: eurohot, style: "w-28 xl:w-60 mx-4" },
    { img: best_therm, style: "w-32 xl:w-60 mx-4" },
    { img: itap, style: "w-36 xl:w-60 mx-4" },
    { img: proaqua, style: "w-30 xl:w-64 mx-4" },
    { img: royal_thermo, style: "w-24 xl:w-48 mx-4" },
    { img: tank, style: "w-34 xl:w-68 mx-4" },
  ], []);

  return (
    <Marquee
      speed={80}
      pauseOnClick={true}
      className=""
    >
      {
        companies.map((item, index) => (
          <img key={index} className={item.style} src={item.img} alt="partners logo" loading="lazy" />
        ))
      }
    </Marquee >
  );
});

export default MarqueeComp;
