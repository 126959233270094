import { useForm } from "react-hook-form";
import axios from "axios";
import FormModal from "./formModal";
import "../index.scss";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { useTranslation } from "react-i18next";

const Form = ({ onClose, isOpen, handleMessage }) => {
	const errorStyle = {
		border: "2px solid red",
	};
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		const botToken = "6320514697:AAHILk4T20hkA2VxGM3rjNKkEOZhAYItgQU";
		const chatId = "-1002001010027";

		const res = await axios
			.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
				chat_id: chatId,
				text: `Name: ${data.name}\nPhone: ${data.phone}\nEmail: ${data.email}\nComment: ${data.comment}`,
			})
			.then((res) => {
				handleMessage(true);
				onClose();
				reset({ name: "", phone: "" });
				// console.log("Telegram API response:", res.data);
			})
			.catch((err) => {
				console.error("Error sending Telegram message:", err);
			});

		setTimeout(() => {
			handleMessage(false);
		}, 3000);

		return res
	};

	return (
		<>
			<FormModal isOpen={isOpen} onClose={onClose}>
				<div
					className="py-5 px-6 xl:py-7 xl:px-10 xl:max-w-[560px] w-11/12 shadow-lg outline-none focus:outline-none bg-white rounded-xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
					onClick={(e) => e.stopPropagation()}
				>
					<h2 className="text-center text-3xl mb-4 font-serif">
						{t("application")}
					</h2>
					<form
						className="flex flex-col justify-center"
						onSubmit={handleSubmit(onSubmit)}
					>
						<label htmlFor="name" className="mb-1 text-sm">
							{t("your name")}
						</label>
						<input
							{...register("name", {
								required: t("enter name"),
								pattern: `^[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+$`,
							})}
							id={"name"}
							className="form_inp"
							style={errors.name?.type === "pattern" ? errorStyle : undefined}
						/>

						{errors.name?.type === "required" ? (
							<span className="text-xs text-red-600 font-medium">
								{errors.name?.message}
							</span>
						) : null}

						<label htmlFor="phone" className="mb-1 mt-4 text-sm">
							{t("your phone")}
						</label>
						<input
							{...register("phone", {
								required: t("enter phone"),
								pattern:
									/^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/,
							})}
							id={"phone"}
							type="tel"
							className="form_inp"
							style={errors.phone?.type === "pattern" ? errorStyle : undefined}
						/>
						{errors.phone?.type === "required" ? (
							<span className="text-xs text-red-600 font-medium">
								{errors.phone?.message}
							</span>
						) : null}

						<label htmlFor="email" className="mb-1 mt-4 text-sm">
							{t("your email")}
						</label>
						<input
							{...register("email", {
								required: t("enter email"),
								pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
							})}
							className="form_inp"
							style={errors.email?.type === "pattern" ? errorStyle : undefined}
						/>
						{errors.email?.type === "required" ? (
							<span className="text-xs text-red-600 font-medium">
								{errors.email?.message}
							</span>
						) : null}
						<label htmlFor="comment" className="mb-1 mt-4 text-sm">
							{t("your comment")}
						</label>
						<input
							{...register("comment")}
							className="form_inp"
							placeholder={t("not required")}
						/>
						<button className="w-full h-14 mt-4 text-lg  bg-green-500 active:bg-green-600 hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-white rounded-3xl uppercase font-semibold">
							{t("send")}
						</button>
					</form>
					<div className="h-[1px] mb-4 mt-1 bg-[#ccc]"></div>
					<p className="text-[#7d7f95] text-sm">
						{t("entered info")}
					</p>
				</div>
			</FormModal>
		</>
	);
};

export default Form;
