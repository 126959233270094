import { useTranslation } from "react-i18next";
import santex_profi_slide_2 from "../../../../assets/videos/santex-profi-slide-2.mp4"
import "../index.scss"
import santex_profi_img from "../../../../assets/images/santex_profi_img.webp"
import { useEffect, useState } from "react";
import LazyVideo from "../../../components/service/lazyVideo";
const Main = () => {
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileDevice = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);
        setIsMobile(isMobileDevice);
    }, []);


    return (
        <section className="bg-black/90 relative">
            <div className="video_div relative mx-auto">
                {isMobile ? (
                    <img className="xl:hidden w-full object-cover h-screen pointer" sizes="100vw" src={santex_profi_img} alt="santex profi img" />
                ) : (
                    <LazyVideo src={santex_profi_slide_2} />
                )}
                <div className="cover w-full h-screen top-0 bg-black/30 absolute"></div>
                <h1 className="service_title">
                    <span className="text-[#2ecc71]">
                        {t("true")}
                    </span>
                    {" "}
                    {t("service center title")}
                </h1>
            </div >
        </section >
    )
}

export default Main