import "../index.scss";
import React from "react";
import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import { Autoplay, EffectFade } from "swiper/modules";

const SwiperFade = ({ children }) => {
    return (
        <div className="SwiperContainer w-full h-full relative z-[-1]">
            <Swiper
                effect="fade"
                modules={[Autoplay, EffectFade]}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                crossfade="true"
                slidesPerView={1}
                loop={true}
                className="mySwiper"
            >
                {children}
            </Swiper>
        </div>
    );
};

export default SwiperFade;
