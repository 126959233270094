import { useTranslation } from "react-i18next";
import bathello_logo from "../../../assets/images/Bathello.webp";
import "../../index.scss";
import { useCallback } from "react";
import Burger from "../../../components/burger";
import BNavigation from "./Bnavigation";

const Header = ({ hide, setHide, sections }) => {
    const { i18n } = useTranslation();

    const handleLanguageChange = useCallback((e) => {
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage);
    }, [i18n]);

    const isMobile = window.innerWidth <= 1280;

    return (
        <header className="Bheader">
            <div className="w-full flex relative h-12 justify-between items-center">
                {isMobile && (
                    <div className="relative -top-[12px] text-center">
                        <Burger>
                            <BNavigation
                                hide={hide}
                                setHide={setHide}
                                sections={sections}
                            />
                        </Burger>
                    </div>
                )}
                <img src={bathello_logo} className="xl:w-72 xl:h-[52px] h-12 w-48 object-cover" width="192" height="48" alt="bathello logo" />
                <div className="flex items-center gap-4">
                    {!isMobile && (
                        <BNavigation
                            hide={hide}
                            setHide={setHide}
                            sections={sections}
                        />
                    )}
                    <select
                        onChange={handleLanguageChange}
                        className="toggle_lang_bathello absolute right-0 xl:relative"
                    >
                        <option className="bg-black text-xs hover:text-blue-600 xl:text-base" value="ru">Ru</option>
                        <option className="bg-black text-xs hover:text-blue-600 xl:text-base" value="en">En</option>
                    </select>
                </div>
            </div>
        </header>
    );
};

export default Header;
