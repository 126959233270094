import TES from "../../../../assets/images/TES.webp"
import santex_profi from "../../../../assets/images/santex_profi.webp"
import Logo from "../../../../assets/images/C_house_logo.png"
const Logos = () => {
    const Logos_arr = [
        { img: TES },
        { img: santex_profi },
        { img: Logo },
    ]
    return (
        <section className="bg-gradient-to-b to-70% from-black/85 to-black/95">
            <div className="w-11/12 mx-auto py-20">
                <ul className="logos_box">
                    {Logos_arr.map((item, i) => (
                        <li key={i}>
                            <img src={item.img} width="160" height="160" alt="services logos" />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
export default Logos