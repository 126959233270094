import { useTranslation } from "react-i18next";
import "../index.scss";
import { motion } from "framer-motion";
import Bform from "../components/common/Bform";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";

const Bcontacts = ({ Status }) => {
    const { t } = useTranslation();


    return (
        <section className="absolute top-0 w-full h-full">
            <div className="relative top-1/2 gap-5 -translate-y-1/2 flex w-11/12 mx-auto h-fit justify-between flex-col xl:flex-row items-center">
                <div className="xl:w-[46%] w-full hidden h-fit text-white xl:flex flex-col xl:gap-6 gap-3">
                    <motion.h1
                        initial={{ height: 0 }}
                        animate={Status ? { height: 0 } : { height: "auto" }}
                        transition={{ duration: 0.4 }}
                        className="xl:text-6xl text-5xl font-semibold overflow-hidden w-fit"
                    >
                        {t("contacts")}
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, scale: .6 }}
                        animate={!Status ? { opacity: 1, scale: 1 } : { opacity: 0, scale: .6 }}
                        transition={{ duration: 0.4 }}
                        className="xl:text-xl text-lg w-fit overflow-hidden"
                    >
                        {t("contacts text")}
                    </motion.p>
                    <motion.nav
                        className="flex gap-4 items-center"
                        initial={{ opacity: 0 }}
                        animate={Status ? { opacity: 0 } : { opacity: 1 }}
                        transition={{ duration: .4 }}
                    >
                        <a href="https://www.instagram.com/climate_house_samarqand/"><FaInstagram className=" w-6 h-6 cursor-pointer" /></a>
                        <a href="https://m.facebook.com/climatehousesam"><FaFacebook className=" w-6 h-6 cursor-pointer" /></a>
                        <a href="https://t.me/SantexProfiService9660"><FaTelegramPlane className="w-6 h-6 cursor-pointer" /></a>
                        <a href="mailto:climatehousesam@gmail.com   "><MdOutlineMail className="w-6 h-6 cursor-pointer" /></a>
                    </motion.nav>
                </div>
                <div className="xl:w-[44%] w-full relative flex flex-col">
                    <h1 className="text-white text-5xl xl:hidden mb-10">{t("application")}</h1>
                    <Bform Status={Status} />
                    <motion.nav
                        className="flex gap-4 absolute xl:hidden -bottom-8 right-10 items-center text-white"
                        initial={{ opacity: 0 }}
                        animate={Status ? { opacity: 0 } : { opacity: 1 }}
                        transition={{ duration: .4 }}
                    >
                        <a href="https://www.instagram.com/climate_house_samarqand/"><FaInstagram className=" w-6 h-6 cursor-pointer" /></a>
                        <a href="https://m.facebook.com/climatehousesam"><FaFacebook className=" w-6 h-6 cursor-pointer" /></a>
                        <a href="https://t.me/SantexProfiService9660"><FaTelegramPlane className="w-6 h-6 cursor-pointer" /></a>
                        <a href="mailto:climatehousesam@gmail.com   "><MdOutlineMail className="w-6 h-6 cursor-pointer" /></a>
                    </motion.nav>
                </div>
            </div>
        </section >
    );
};

export default Bcontacts;