import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import BSwiper from "../components/common/BSwiper";

const BMain = ({ Status }) => {
    const { t } = useTranslation();

    const isMobile = window.innerWidth <= 1280;

    return (
        <div className="absolute top-0 w-full h-full">
            <motion.div
                className="flex flex-col xl:flex-row justify-center gap-10 xl:justify-between items-center w-11/12 mx-auto h-full"
            >
                <div className="xl:w-[44%] w-full flex flex-col xl:gap-4 gap-6 text-white">
                    <motion.h1
                        initial={{ height: 0 }}
                        animate={Status ? { height: 0 } : { height: "auto" }}
                        transition={{ duration: 0.4 }}
                        className="BMain_title text-5xl xl:text-6xl overflow-hidden w-fit"
                    >
                        Say Hello <br /> to <span className="bathello_color">Bathello</span>
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, scale: 0.6 }}
                        animate={Status ? { opacity: 0, scale: 0.6 } : { opacity: 1, scale: 1 }}
                        transition={{ duration: 0.4 }}
                        className="text-xl w-full"
                    >
                        {t("bathello text")}
                    </motion.p>
                </div>
                <motion.div
                    className="about_swiper"
                    initial={{ opacity: 0, x: !isMobile ? 500 : 0 }}
                    animate={Status ? { opacity: 0, x: !isMobile ? 500 : 0 } : { opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                    <BSwiper />
                </motion.div>
            </motion.div>
        </div >
    );
};

export default BMain;
