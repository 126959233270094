import Main from "./sections/Main"
import About from "./sections/About"
import Advantages from "./sections/Advantages"
import ContactsPage from "./sections/ContactsPage"
import Partners from "./sections/Partners"
const Home = () => {
    return (
        <>
            <Main />
            <About />
            <Advantages />
            <ContactsPage />
            <Partners />
        </>
    )
}
export default Home