import React from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import "../index.scss";
import SwiperFade from "../../../../components/Swiper";
import toilet_1 from "../../../../assets/images/toilet_1.webp"
import toilet_2 from "../../../../assets/images/toilet_2.webp"
import toilet_3 from "../../../../assets/images/toilet_3.webp"
import toilet_4 from "../../../../assets/images/toilet_4.webp"
import toilet_5 from "../../../../assets/images/toilet_5.webp"
import "../BprodsGrid"
import "../index.scss"
const BSwiperToilets = () => {
    return (
        <SwiperFade>
            <div className="toilets">
                <SwiperSlide>
                    <img className="swiper_img" src={toilet_1} alt="toilets img" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={toilet_2} alt="toilets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={toilet_3} alt="toilets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={toilet_4} alt="toilets img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" src={toilet_5} alt="toilets img" loading="lazy" />
                </SwiperSlide>
            </div>
        </SwiperFade>
    );
};

export default BSwiperToilets;
