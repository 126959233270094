import "./index.scss"
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import SwiperFade from "../../../components/Swiper";
import bathRoom_1 from "../../../assets/images/bathRoom_1.webp"
import bathRoom_2 from "../../../assets/images/bathRoom_2.jfif"
import bathRoom_3 from "../../../assets/images/bathRoom_3.webp"
import bathRoom_4 from "../../../assets/images/bathRoom_4.webp"
import bathRoom_5 from "../../../assets/images/bathRoom_5.jfif"
const BProdsGrid = () => {
    return (
        <SwiperFade>
            <div className="bathRooms">
                <SwiperSlide>
                    <img className="swiper_img" id="table_1" src={bathRoom_1} alt="bathroom img" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_2" src={bathRoom_2} alt="bathroom img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_3" src={bathRoom_3} alt="bathroom img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_4" src={bathRoom_4} alt="bathroom img" loading="lazy" />
                </SwiperSlide>
                <SwiperSlide className="">
                    <img className="swiper_img" id="table_5" src={bathRoom_5} alt="bathroom img" loading="lazy" />
                </SwiperSlide>
            </div>
        </SwiperFade>
    )
}
export default BProdsGrid