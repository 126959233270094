import showroom_3 from "../../../../assets/images/showroom_3.webp";
import { useTranslation } from "react-i18next";
import "../index.scss";
import { useRef } from "react";
import { easeInOut, useInView } from "framer-motion";
import { motion } from "framer-motion";
const About = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const comomonAnim = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: .1,
        duration: 0.7,
        ease: easeInOut,
      },
    },
    hiddenLeft: {
      opacity: 0,
      x: -150,
    },
    hidden: {
      opacity: 0
    }
  }

  return (
    <section
      id="about"
      className=" bg-black/90 flex flex-col gap-2 xl:gap-10 py-10 xl:py-20"
    >
      <div
        ref={ref}
        className="w-11/12 mx-auto gap-10 items-end flex justify-between relative text-center"
      >
        <div className="section_num right-[-2%] top-[-17%] text-[#808080]">
          02
        </div>
        <motion.div
          className="w-7/12 hidden xl:block"
        >
          <motion.img
            variants={comomonAnim}
            initial="hiddenLeft"
            animate={isInView ? "visible" : "hiddenLeft"}
            className="opacity-90 h-[400px] w-full object-cover rounded-xl"
            id="3"
            src={showroom_3}
            width={1003}
            height={400}
            alt="show room img"
          />
        </motion.div>
        <motion.div
          className="w-full h-full xl:w-5/12">
          <motion.h2
            variants={comomonAnim}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            className="CAbout_us_title text-orange-600/90 text-5xl xl:text-[85px] pb-5 xl:pb-6">
            {t("about us")}
          </motion.h2>

          <motion.p
            variants={comomonAnim}
            initial="hiddenRight"
            animate={isInView ? "visible" : "hidden"}
            className="text-white w-full text-[12px] xl:text-lg mb-10">
            {t("about us text")}
          </motion.p>
        </motion.div>
      </div>
      <div
        ref={ref}
      >
        <motion.h2
          className="text-white text-3xl xl:text-6xl text-center my-6 xl:my-12"
          variants={comomonAnim}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          {t("we're in nums")}
        </motion.h2>
        <motion.ul
          className="flex w-11/12 h-fit xl:h-[200px] mx-auto justify-between items-center"
          variants={comomonAnim}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <li className="experience_block">
            <p>20+</p>
            <span>{t("we're in nums descr")}</span>
          </li>
          <li className="experience_block border-x-2 xl:border-x-4 h-full border-[#1130d1]/80">
            <p>10+</p>
            <span>{t("we're in nums descr1")}</span>
          </li>
          <li className="experience_block">
            <p>25+</p>
            <span>{t("we're in nums descr2")}</span>
          </li>
        </motion.ul>
      </div>
    </section>
  );
};

export default About;