import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import BurgerModal from "./burgerModal";

const Burger = ({ children }) => {
    const [isOpen, setIsOpened] = useState(false);

    const openModal = () => {
        setIsOpened(true);
    };
    const closeModal = () => {
        setIsOpened(false);
    };

    return (
        <>
            <div className="absolute left-3 block xl:hidden" onClick={openModal}>
                <RxHamburgerMenu size={28} color="#fff" className="block"></RxHamburgerMenu>
            </div>

            <BurgerModal isOpen={isOpen} onClose={closeModal}>
                {children}
            </BurgerModal>
        </>
    )
}
export default Burger