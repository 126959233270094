import { useEffect, useState } from "react";

const LazyVideo = ({ src }) => {
    const [videoSrc, setVideoSrc] = useState(null);

    useEffect(() => {
        const videoElement = document.createElement("video");
        videoElement.src = src;
        videoElement.onloadstart = () => {
            setVideoSrc(src);
        };
        return () => {
            videoElement.onloadstart = null;
        };
    }, [src]);

    return videoSrc && <video className="video hidden xl:block w-full object-fill h-screen pointer-events-none" autoPlay playsInline loop muted>
        <source src={videoSrc} type="video/mp4" />
    </video>;
};
export default LazyVideo;