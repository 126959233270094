import React from 'react'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import BProdsGrid from './BprodsGrid';

const CreateMainPage = ({ Status }) => {
    const { t } = useTranslation();

    return (
        <div className="w-11/12 relative flex flex-col xl:flex-row items-center justify-center gap-6 xl:justify-between top-0 h-full mx-auto">
            <div className="xl:w-[40%] w-full">
                <motion.h1
                    initial={{ height: 0 }}
                    animate={Status ? { height: 0 } : { height: "auto" }}
                    transition={{ duration: 0.4 }}
                    className="text-5xl mb-4 xl:text-6xl overflow-hidden text-white pb-3">{t("products")}
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, scale: 0.6 }}
                    animate={Status ? { opacity: 0, scale: 0.6 } : { opacity: 1, scale: 1 }}
                    transition={{ duration: 0.4 }}
                    className="xl:text-xl text-lg text-white"
                >
                    {t("bathello_prods_text")}
                </motion.p>
            </div>
            <motion.div
                className="prods_swiper relative"
                initial={{ opacity: 0, scale: .6 }}
                animate={Status ? { opacity: 0, scale: .6 } : { opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <BProdsGrid />
            </motion.div>
        </div>
    )
}

export default CreateMainPage
