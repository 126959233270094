import axios from "axios";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState } from "react";

const Bform = ({ Status }) => {
    const [inputFocus, setInputFocus] = useState({
        name: false,
        phone: false,
        email: false,
    });
    const [focusComment, setFocusComment] = useState();

    const errorStyle = {
        "border-bottom": "2px solid red",
    };
    const { t } = useTranslation();

    const motionVariant = {
        initial: { y: -20 },
        animate: { y: 0 },
        transition: { type: "spring", stiffness: 300 },
    };

    const handleInputFocus = (input) => {
        setInputFocus({ ...inputFocus, [input]: true });
    };

    const handleInputBlur = (input) => {
        setInputFocus({ ...inputFocus, [input]: false });
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const botToken = "6320514697:AAHILk4T20hkA2VxGM3rjNKkEOZhAYItgQU";
        const chatId = "-1002001010027";

        const res = await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            chat_id: chatId,
            text: `Name: ${data.name}\nPhone: ${data.phone}\nEmail: ${data.email}\nComment: ${data.comment}`,
        })
            .then((res) => {
                reset({ name: "", phone: "" });
                // console.log("Telegram API response:", res.data);
            })
            .catch((err) => {
                console.error("Error sending Telegram message:", err);
            });

        return res
    };
    return (
        <>
            <motion.div
                className="xl:max-w-full w-full outline-none focus:outline-none"
                onClick={(e) => e.stopPropagation()}
                initial={{ opacity: 0 }}
                animate={Status ? { opacity: 0 } : { opacity: 1 }}
                transition={{ duration: .5 }}
            >
                <form
                    className="flex flex-col relative w-full justify-center gap-"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {[
                        { label: t("your name"), name: "name", pattern: `^[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+$` },
                        { label: t("your phone"), name: "phone", pattern: /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/ },
                        { label: t("your email"), name: "email", pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ },
                    ].map((input, index) => (
                        <div className="Bform_parent" key={index}>
                            <motion.label
                                htmlFor={input.name}
                                className={`Bform_label ${inputFocus[input.name] ? "text-green-500" : "text-white"}`}
                                {...motionVariant}
                            >
                                {input.label}<p className="inline ml-[2px] text-sm">*</p>
                            </motion.label>
                            <motion.input
                                {...register(input.name, {
                                    required: t(`enter ${input.name}`),
                                    pattern: input.pattern,
                                })}
                                id={input.name}
                                className={`Bform_inp ${inputFocus[input.name] ? "black_border" : ""}`}
                                style={errors[input.name]?.type === "pattern" ? errorStyle : undefined}
                                onFocus={() => handleInputFocus(input.name)}
                                onBlur={() => handleInputBlur(input.name)}
                            />
                            {errors[input.name]?.type === "required" && (
                                <motion.span
                                    className="Bform_error"
                                    {...motionVariant}
                                >
                                    {errors[input.name]?.message}
                                </motion.span>
                            )}
                        </div>

                    ))}
                    <div className="Bform_parent">
                        <motion.label
                            htmlFor="comment"
                            className={`Bform_label ${focusComment ? "text-green-500" : "text-white"}`}
                            {...motionVariant}
                        >
                            {t("your comment")}
                        </motion.label>
                        <motion.input
                            {...register("comment")}
                            className={`Bform_inp ${focusComment ? "black_border" : ""}`}
                            onFocus={() => setFocusComment(true)}
                            onBlur={() => setFocusComment(false)}
                        />
                    </div>
                    <button className="w-32 h-12 absolute -bottom-16 text-base bg-white active:bg-white hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-black rounded-3xl font-semibold">
                        {t("send")}
                    </button>
                </form>
            </motion.div >
        </>
    )
}
export default Bform;




// import axios from "axios";
// import { useForm } from "react-hook-form";
// import { useTranslation } from "react-i18next";
// import { motion } from "framer-motion";
// import { useState } from "react";


// const Bform = ({ Status }) => {
//     const [isInputFocused, setIsInputFocused] = useState(false);
//     const errorStyle = {
//         border: "2px solid red",
//     };
//     const { t } = useTranslation();

//     const motionVariant = {
//         initial: { y: -20 },
//         animate: { y: 0 },
//         transition: { type: "spring", stiffness: 300 },
//     };

//     const handleFocus = () => {
//         setIsInputFocused(true);
//     };

//     const handleBlur = () => {
//         setIsInputFocused(false);
//     };

//     const {
//         register,
//         handleSubmit,
//         reset,
//         formState: { errors },
//     } = useForm();

//     const onSubmit = async (data) => {
//         const botToken = "6320514697:AAHILk4T20hkA2VxGM3rjNKkEOZhAYItgQU";
//         const chatId = "-1002001010027";

//         const res = await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
//             chat_id: chatId,
//             text: `Name: ${data.name}\nPhone: ${data.phone}\nEmail: ${data.email}\nComment: ${data.comment}`,
//         })
//             .then((res) => {
//                 reset({ name: "", phone: "" });
//                 // console.log("Telegram API response:", res.data);
//             })
//             .catch((err) => {
//                 console.error("Error sending Telegram message:", err);
//             });

//         return res
//     };
//     return (
//         <>
//             <motion.div
//                 className="xl:max-w-[44%] w-full outline-none focus:outline-none"
//                 onClick={(e) => e.stopPropagation()}
//                 initial={{ opacity: 0 }}
//                 animate={Status ? { opacity: 0 } : { opacity: 1 }}
//                 transition={{ duration: .5 }}
//             >
//                 <form
//                     className="flex flex-col w-full justify-center gap-"
//                     onSubmit={handleSubmit(onSubmit)}
//                 >
//                     <div className="Bform_parent">
//                         <motion.label
//                             htmlFor="name"
//                             className={`Bform_label ${isInputFocused ? "text-green-500" : "text-white"}`}
//                             {...motionVariant}                        >
//                             {t("your name")}
//                         </motion.label>
//                         <motion.input
//                             {...register("name", {
//                                 required: t("enter name"),
//                                 pattern: `^[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+$`,
//                             })}
//                             id={"name"}
//                             className={`Bform_inp ${isInputFocused ? "black_border" : ""}`}
//                             style={errors.name?.type === "pattern" ? errorStyle : undefined}
//                             onFocus={handleFocus}
//                             onBlur={handleBlur}
//                         />
//                         {errors.name?.type === "required" ? (
//                             <motion.span
//                                 className="Bform_error"
//                                 {...motionVariant}
//                             >
//                                 {errors.name?.message}
//                             </motion.span>
//                         ) : null}
//                     </div>
//                     <div className="Bform_parent">
//                         <motion.label
//                             htmlFor="phone"
//                             className={`Bform_label ${isInputFocused ? "text-green-500" : "text-white"}`}
//                             {...motionVariant}
//                         >
//                             {t("your phone")}
//                         </motion.label>
//                         <motion.input
//                             {...register("phone", {
//                                 required: t("enter phone"),
//                                 pattern:
//                                     /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/,
//                             })}
//                             id={"phone"}
//                             type="tel"
//                             className={`Bform_inp ${isInputFocused ? "black_border" : ""}`}
//                             style={errors.phone?.type === "pattern" ? errorStyle : undefined}
//                             onFocus={handleFocus}
//                             onBlur={handleBlur}
//                         />
//                         {errors.phone?.type === "required" ? (
//                             <motion.span
//                                 className="Bform_error"
//                             >
//                                 {errors.phone?.message}
//                             </motion.span>
//                         ) : null}
//                     </div>
//                     <div className="Bform_parent">
//                         <motion.label htmlFor="email"
//                             className={`Bform_label ${isInputFocused ? "text-green-500" : "text-white"}`}
//                             {...motionVariant}
//                         >
//                             {t("your email")}
//                         </motion.label>
//                         <motion.input
//                             {...register("email", {
//                                 required: t("enter email"),
//                                 pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
//                             })}
//                             className={`Bform_inp ${isInputFocused ? "black_border" : ""}`}
//                             style={errors.email?.type === "pattern" ? errorStyle : undefined}
//                             onFocus={handleFocus}
//                             onBlur={handleBlur}
//                         />
//                         {errors.email?.type === "required" ? (
//                             <motion.span
//                                 className="Bform_error"
//                             >
//                                 {errors.email?.message}
//                             </motion.span>
//                         ) : null}
//                     </div>
//                     <div className="Bform_parent">
//                         <motion.label
//                             htmlFor="comment"
//                             className={`Bform_label ${isInputFocused ? "text-green-500" : "text-white"}`}
//                             {...motionVariant}
//                         >
//                             {t("your comment")}
//                         </motion.label>
//                         <motion.input
//                             {...register("comment")}
//                             className={`Bform_inp ${isInputFocused ? "black_border" : ""}`}

//                             placeholder={t("not required")}
//                             onFocus={handleFocus}
//                             onBlur={handleBlur}
//                         />
//                     </div>
//                     <button className="w-32 h-12 text-base bg-white active:bg-white hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 text-black rounded-3xl font-semibold">
//                         {t("send")}
//                     </button>
//                 </form>
//             </motion.div >
//         </>
//     )
// }
// export default Bform