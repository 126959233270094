import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
const Message = ({ status }) => {
  return (
    <AnimatePresence>
      {status && (
        <motion.div
          initial={{ opacity: 0, x: "-50%", y: -20 }}
          animate={{ opacity: 1, x: "-50%", y: 16 }}
          exit={{ opacity: 0, x: "-50%", y: -20 }}
          transition={{ duration: 0.3 }}
          className="w-fit px-3 py-4 bg-green-500 flex items-center gap-2 rounded-lg text-[#fff] font-medium shadow-[0px_0px_10px_#00000040] fixed z-50 left-1/2"
        >
          <FaCheckCircle size={20} color="#fff" />
          <p className="whitespace-nowrap">Заявка отправлена!</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Message;
