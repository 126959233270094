import React, { useState, useEffect, useContext } from "react";
import BSwiper from "../components/common/BSwiper";
import Header from "../components/header/header";
import "./index.scss";
import Bcontacts from "./Bcontacts";
import BMain from "./Bmain";
import BprodsPage from "./BprodsPage";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { debounce } from 'lodash';
import PagesContext from "../context/PagesContext";
const BathelloPages = () => {
    const [hide, setHide] = useState(false);
    const { t } = useTranslation();
    const { page, toggleNextComponent } = useContext(PagesContext)
    const { isOpen, currentPage } = page

    const sections = [
        { id: "main", component: <BMain key="main" Status={currentPage === "main" && isOpen} />, name: t("main") },
        { id: "contacts", component: <Bcontacts key="contacts" Status={currentPage === "contacts" && isOpen} />, name: t("contacts") },
        { id: "products", component: <BprodsPage key="BprodsPage" Status={currentPage === "products" && isOpen} />, name: t("products"), },
    ];

    const handleWheel = debounce((event) => {
        if (isOpen) return;

        const currentIndex = sections.findIndex(section => section.id === currentPage);
        const isScrollingDown = event.deltaY > 0;
        const newIndex = isScrollingDown ? currentIndex + 1 : currentIndex - 1;

        if (newIndex >= 0 && newIndex < sections.length) {
            toggleNextComponent(sections[newIndex].id);
        }
    }, 25);

    useEffect(() => {
        if (isOpen) return;
        // window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [currentPage, sections.length, isOpen]);

    console.log(sections);

    return (
        <div className="max-w-full w-full relative bg-blue-600 xl:bg-transparent h-screen bg-red">
            <Header hide={hide} setHide={setHide} sections={sections} />
            <div className="Bmain_content relative h-screen">
                <BSwiper />
                <div className={`cover duration-500 ${hide ? "opacity-0" : "backdrop-blur-[3px] bg-black/85"} absolute left-0 right-0 bottom-0 top-0`}></div>
                <div className={`transition-opacity duration-300 ${hide ? "opacity-0" : "opacity-100"}`}>
                    {sections.map((section, i) => (
                        <CSSTransition
                            key={i}
                            in={!isOpen && section.id === currentPage}
                            timeout={500}
                            unmountOnExit
                        >
                            {section.component}
                        </CSSTransition>
                    ))}
                </div>
            </div>
        </div >
    );
};

export default BathelloPages;
