import { useLayoutEffect } from "react";
import Logos from "./sections/Logos"
import Main from "./sections/Main"
import Centers from "./sections/centers"


const ServicePage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Main />
            <Logos />
            <Centers />
        </>
    )
}

export default ServicePage